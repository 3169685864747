import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createLayout, deleteLayout, getAllLayouts, updateLayout } from '../api/services/default/layout';
import Drawer from '@material-ui/core/Drawer';
import { AlertService } from './alerts';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function FlairTable({
  data, 
  columns, 
  isLoading, 
  customizedGridOptions,
  getRowHeight,
  isRowSelectable,
  onSelectionChange,
  onCellClicked,
  rowClassRules,
  excelStyles,
  getContextMenuItems,
  onCustomGridReady = null,
  actions = [], 
  otherAction = {}, 
  tableRef = null,
  onSearchChange = () => { }, 
  onFilterChange = () => { },
  filtering = true, 
  pivotMode = false,
  hideSearch = false,
  serverSidePagination = false,
}) {
  const [searchText, setSearchText] = useState("");
  const gridRef = useRef();
  const [appliedLayout, setAppliedLayout] = useState(null);
  const location = useLocation();

  const loggedInUser = useSelector((state) => state.firebase.auth.uid);
  const accessModules = useSelector((state) => state.employee.employeeModules.accessModules);

  const isManager = useMemo(() => {
    const allowedModules = [
      'employees-manager',
      'console-customization',
      'wiki-manager',
      'timesheets-manager',
      // 'immigration-manager',
      'accounts-manager',
      'task-management-manager',
      'employee-self-services-manager'
    ];
    return allowedModules.some((module) => accessModules.includes(module));
  }, [accessModules]);
  const isAdmin = useMemo(() => accessModules.includes('console-customization'), [accessModules]); 

  const queryClient = useQueryClient();

  const { mutateAsync: createLayoutMutationFn } = useMutation({
		mutationFn: ({ ...mutationProps }) => createLayout({ ...mutationProps }),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["getAllLayouts", location.pathname],
			});
		},
	});

	const { mutateAsync: updateLayoutMutationFn } = useMutation({
		mutationFn: ({ id, definition }) => updateLayout(id, definition),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["getAllLayouts", location.pathname],
			});
		},
	});

  const initialColumnDefs = useMemo(() => convertMaterialToAgGridColumns(columns), [columns]);

  const [columnDefs, setColumnDefs] = useState(initialColumnDefs);

  const onGridReady = useCallback(params => {
    if (tableRef) {
      tableRef.current = params.api;
    }
  }, [tableRef]);

  useEffect(() => {
    if (isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading, gridRef.current?.api]);

  const onFilterChanged = useCallback(event => {
    onFilterChange(event.api.getFilterModel());
  }, [onFilterChange]);

  const onSearchTextChanged = useCallback(
    (event) => {
      setSearchText(event.target.value);
    },
    []
  );

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      pivotMode: pivotMode,
    };
  }, [pivotMode]);

  const sidebarOptions = useMemo(() => {
    return [
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      }
    ]
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agAggregationComponent' },
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
    ],
    };
  }, []);

  const gridOptions = {
    autoSizeColumns: true,
    pagination: true,
    paginationPageSize: 1000,
  };


	const renderTableActions = useCallback(() => {
		let actionsTobeRendered = [];
		if (typeof actions === "object" && Object.keys(actions).length > 0) {
			actionsTobeRendered.push(actions);
		}
		if (
			typeof otherAction === "object" &&
			Object.keys(otherAction).length > 0
		) {
			actionsTobeRendered.push(otherAction);
		}
		if (Array.isArray(actions) && actions.length > 0) {
			actionsTobeRendered = [...actionsTobeRendered, ...actions];
		}
		return (
			<div
				style={{ display: "flex", justifyContent: "flex-end" }}
				className="mb-1"
			>
				{actionsTobeRendered.map(({ icon, onClick = () => {} }, index) => (
					<span onClick={onClick} key={index} style={{ margin: "0 8px" }}>
						{icon()}
					</span>
				))}
			</div>
		);
	}, [actions, otherAction]);

	const saveState = useCallback(async () => {
		try {
			const columnState = gridRef.current?.columnApi.getColumnState();
			const alertService = new AlertService();

			if (appliedLayout) {
        const confirmResult = await alertService.confirmMessage(
          "You have changes in the layout",
          "Do you want to save the changes?",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }
        );
        if (!confirmResult.isConfirmed) {
          return;
        }
				const result = await alertService.confirmMessage(
					"Do you want to create a new layout or update the existing one?",
					"Creating a new layout will not affect the existing layout",
					{
						confirmButtonText: "Create New",
						cancelButtonText: "Update Existing",
					}
				);
				if (!result.isConfirmed) {
					await updateLayoutMutationFn({
						id: appliedLayout.id,
						definition: columnState,
					});
					return;
				}
			}
			const saveResult = await alertService.confirmWithReason({
				title: "Are you sure you want to save the layout?",
				message: "Please enter a name for the layout",
				inputPlaceholder: "Enter layout name",
			});
			if (!saveResult.isConfirmed) {
				return;
			}
			const { data: createdId } = await createLayoutMutationFn({
				definition: columnState,
				tableUrl: location.pathname,
				name: saveResult.value,
			});
      setAppliedLayout({
        id: createdId,
        name: saveResult.value,
        createdBy: loggedInUser,
      });
		} catch (error) {
			console.error("[FlairTable] Error saving column state", error);
		}
	}, [
		appliedLayout,
		createLayoutMutationFn,
		updateLayoutMutationFn,
    location.pathname,
    loggedInUser,
	]);

	const restoreState = useCallback(
		async ({ layout, columnState }) => {
			try {
        setAppliedLayout(layout);
				gridRef.current?.columnApi.applyColumnState({
					state: columnState,
					applyOrder: true,
				});
			} catch (error) {
				console.error("[FlairTable] Error restoring column state", error);
			}
		},
		[]
	);

	const resetState = useCallback(() => {
		try {
      setAppliedLayout(null);
			gridRef.current?.columnApi.resetColumnState();
		} catch (error) {
			console.error("[FlairTable] Error resetting column state", error);
		}
	}, []);

  return (
    <div style={{ height: window.innerHeight * 0.75 + "px", width: '100%' }}>
      {isManager && (
				<div className="d-flex justify-content-between items-center">
					<div className="d-flex gap-2 mb-2">
						<button
							className="btn btn-primary"
							onClick={saveState}
							hidden={
                appliedLayout &&
                appliedLayout.createdBy !== loggedInUser &&
                !isAdmin
              }
						>
							Save Layout
						</button>
						<RestoreLayoutView applyLayout={restoreState} />
						<button 
              className="btn btn-primary" 
              onClick={resetState}
              hidden={!appliedLayout}
            >
							Restore Default
						</button>
					</div>
					{appliedLayout && (
						<div className="alert alert-info">
							<strong>Layout:</strong> {appliedLayout.name}
						</div>
					)}
				</div>
			)}
      <div className='d-flex justify-content-between'>
        <div style={{ visibility: hideSearch ? 'hidden' : 'visible' }}>
          <input
            type="text"
            placeholder="Search"
            className="form-control mr-2"
            style={{ width: "400px" }}
            onInput={onSearchTextChanged}
          />
        </div>
        {renderTableActions()}
      </div>
      <div className="ag-theme-balham" style={{ height: '64vh', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowData={data}
          rowHeight={33}
          quickFilterText={searchText}
          getRowHeight={getRowHeight}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onCustomGridReady || onGridReady}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          }
          onFilterChanged={onFilterChanged}
          animateRows={true}
          sideBar={{
            toolPanels: sidebarOptions.filter(option => pivotMode || option.id !== 'columns'),
          }}
          enableRangeSelection={true}
          statusBar={statusBar}
          rowSelection='multiple'
          gridOptions={customizedGridOptions ? customizedGridOptions : gridOptions}
          onSelectionChanged={onSelectionChange}
          suppressRowClickSelection={true}
          isRowSelectable={isRowSelectable}
          onCellClicked={onCellClicked}
          rowClassRules={rowClassRules}
          rowModelType={!serverSidePagination ? 'clientSide' : 'serverSide'}
          cacheBlockSize={1000}
          excelStyles={excelStyles}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
    </div>
  )
}

export default FlairTable;

export function convertMaterialToAgGridColumns(materialColumns) {
  return materialColumns.map((column) => { 
    const agColumn = {
      headerName: column.title,
      field: column.field,
      children: column.children,
      columnGroupShow: column.columnGroupShow,
      cellStyle: column?.cellStyle,
      cellClass: column?.cellClass,
      cellClassRules: column?.cellClassRules,
      headerClass: column?.headerClass,
      checkboxSelection: column?.checkboxSelection,
      headerCheckboxSelection: column?.headerCheckboxSelection,
      headerCheckboxSelectionFilteredOnly: column?.headerCheckboxSelectionFilteredOnly,
      showDisabledCheckboxes: column?.showDisabledCheckboxes,
      hide: column?.hide,
      pinned: column?.pinned,
      lockPinned: column?.lockPinned,
      filterParams: column?.filterParams,
      sortable: column?.sortable ?? true,
      menuTabs: column?.menuTabs ?? ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],
    };
    
    if (column.dataType === "Number") {
      agColumn.type = "numericColumn";
    } else if (column.dataType === "Date") {
      agColumn.type = "dateColumn";
    } else {
      agColumn.type = "textColumn";
    }

    if (column.width) {
      agColumn.width = column.width;
    }

    if (column.render && column.render instanceof Function) {
      agColumn.cellRendererFramework = ({ data }) => {
        return (
          <div>
            {column.render(data)}
          </div>
        );
      };
    } else {
      agColumn.cellRenderer = ({ data }) => {
        return (
          <div>
            {data?.[column?.field]}
          </div>
        );
      };
    }

    if (column.lookup && column.lookup instanceof Object) {
      agColumn.cellEditor = "agSelectCellEditor";
      agColumn.cellEditorParams = {
        values: column.lookup,
      };
      agColumn.valueGetter = (params) => {
        const rowData = params.data;
        const lookupValue = column?.lookup?.[rowData?.[column.field]];
        return lookupValue ?? "";
      };
      agColumn.cellRenderer = ({ data, value }) => {
        if (column?.render)
          return (
            <div>
              {column.render(data)}
            </div>
          )
        return (
          <div>
            {column?.lookup?.[value] || value}
          </div>
        );
      };
    }

    return agColumn;
  });
}


function RestoreLayoutView({ applyLayout }) {
	const [open, setOpen] = useState(false);
	const location = useLocation();
  const loggedInUser = useSelector((state) => state.firebase.auth.uid);
  const accessModules = useSelector((state) => state.employee.employeeModules.accessModules);
  const isAdmin = useMemo(() => accessModules.includes('console-customization'), [accessModules]);

	const {
		data: layouts,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ["getAllLayouts", location.pathname],
		queryFn: () => getAllLayouts(location.pathname),
		enabled: open,
		refetchOnMount: "always",
		initialData: [],
	});

	const { mutateAsync: deleteLayoutMutationFn } = useMutation({
		mutationFn: ({ id }) => deleteLayout(id),
		onSuccess: () => {
			refetch();
		},
	});

	useEffect(() => {
		if (open) {
			refetch();
		}
	}, [open, refetch]);

	const openDrawer = useCallback(() => {
		setOpen(true);
	}, []);

	const closeDrawer = useCallback((event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setOpen(false);
	}, []);

	const handleApplyLayout = useCallback(
		(layoutId) => {
			const layout = layouts.find((layout) => layout.id === layoutId);
			if (!layout) {
				return;
			}
			applyLayout({
				layout: { id: layout.id, name: layout.name, createdBy: layout.createdBy },
				columnState: layout.definition,
			});
			setOpen(false);
		},
		[applyLayout, layouts, setOpen]
	);

	return (
		<>
			<button className="btn btn-primary" onClick={openDrawer}>
				Show Layouts
			</button>
			<Drawer anchor="right" open={open} onClose={closeDrawer}>
				<div className="p-2">
					<div className="d-flex justify-content-between">
						<h3 className="m-2">Layouts</h3>
						<button className="btn btn-primary m-2" onClick={refetch}>
							<i className="fa fa-refresh"></i> Refresh
						</button>
					</div>
					{layouts.length === 0 && !isLoading && (
						<div className="text-center">No layouts found</div>
					)}
					<div className="d-flex flex-column gap-2 flex-wrap">
						{layouts.map((layout) => (
							<div key={layout.id} className="d-flex justify-content-between">
								<button
									className="btn btn-primary"
									onClick={() => handleApplyLayout(layout.id)}
								>
									Apply {layout.name}
								</button>
								<button
									className="btn btn-danger"
									onClick={() => deleteLayoutMutationFn({ id: layout.id })}
                  hidden={layout.createdBy !== loggedInUser && !isAdmin}
								>
									<i className="fa fa-trash"></i> Delete
								</button>
							</div>
						))}
					</div>
				</div>
			</Drawer>
		</>
	);
}
