import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isEmpty, isLoaded } from "react-redux-firebase";
import { compose } from "redux";
import Presentation from "./Presentation";
import { loadProject } from "../../middleware/projectsList";
import CircularSpinner from "../../../../shared/circularSpinner";

function Container(props) {
  const { names, projectMembers, handleEmployeesDefault, isAdded } = props;
  const [state, setState] = React.useState({
    employees: [],
  });
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = useState({
    data: [],
  });
  const handleEmployees = (value) => {
    setSelected(value);
    setState({
      ...state,
      employees: value,
    });
    handleEmployeesDefault(value);
  };
  useEffect(() => {
    let data = [];
    if (!props.inProject)
      data = Object.values(names).filter(
        (item) =>
          item.status === "active" &&
          item.uid !== props.auth &&
          item.uid !== undefined &&
          item.uid !== null
      );
    else
      data = Object.values(names).filter((e) => {
        if (projectMembers) {
          return (
            !projectMembers.includes(e.uid) &&
            e.uid !== undefined &&
            e.status === "active" &&
            e.uid !== props.auth &&
            e.uid !== null
          );
        }
        return false;
      });

    setData({
      data: data,
    });
    setSelected([]);
  }, [JSON.stringify(projectMembers), isAdded]);

  if (isLoaded(names)) {
    return (
      <Presentation
        isAdded={isAdded}
        employees={state.employees}
        handleEmployees={handleEmployees}
        data={data.data}
        selected={selected}
      />
    );
  }
  return (
    <CircularSpinner />
  );
}
const mapStateToProps = (state, ownProps) => {
  console.log(state.employee.employeeList.metaInfoofEmployees.data);
  return {
    names: state.employee.employeeList.metaInfoofEmployees.data,
    auth: state.firebase.auth.uid,
  };
};

export default connect(mapStateToProps)(Container);
