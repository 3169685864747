import React from "react";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import MetaInfo from "../../../../../shared/getMetaInfo";
import { differenceInDays } from "date-fns";
import { MdLocationCity } from "react-icons/md";
import { FaEdit, FaAddressCard } from "react-icons/fa";
import FlairTable from "../../../../../shared/FlairTable";
import EmployeeHoverCard from "../../../../../shared/components/EmployeeHoverCard";
import { CalendarFormatter } from "../../../../../shared/CalendarFormatter";
import InactiveChip from "../../../../../shared/lib/Chips/InactiveChip";
import ActiveChip from "../../../../../shared/lib/Chips/ActiveChip";
import RedChip from "../../../../../shared/lib/Chips/RedChip";
import WarningChip from "../../../../../shared/lib/Chips/WarningChip";
import PlacementNotes from "../PlacementNotes";
import PlacementAudit from "../PlacementAudit";
import { useQuery } from "@tanstack/react-query";
import { getAllPlacements } from "../../../../../api/services/default/placements";

function htmlToText(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const textContent = doc.body.textContent || '';
  return textContent;
}

function Presentation(props) {
	const { employeeId, clientId, NewPlacementAction } = props;

	const { data: placements, isFetching: isLoading, refetch } = useQuery({
		queryKey: [
			"getAllPlacements",
			{
				employeeID: employeeId,
				clientId,
				includeSectionsWithEmployee: true,
			},
		],
		queryFn: () =>
			getAllPlacements({
				employeeID: employeeId,
				clientId,
				includeSectionsWithEmployee: true,
			}),
	});

	const metaInfo = new MetaInfo();

	const columns = [
		{
			title: "Employee Name",
			field: "employeeName",
			render: (rowData) => {
				if (rowData.employeeId) {
					return <EmployeeHoverCard employeeID={rowData.employeeId} />;
				}
				return <p>--</p>;
			},
			dataType: "String",
			width: 300,
		},
		{
			title: "Employee ID",
			field: "employeeId",
			dataType: "String",
			render: (rowData) => {
				if (rowData.employeeId) {
					return (
						<div style={{ width: 130, paddingLeft: 5 }}>
							<Link
								style={{ color: "#3f51b5" }}
								to={"/console/employees/" + rowData.employeeId}
							>
								<FaAddressCard size={24} /> <b>{rowData.employeeId}</b>{" "}
							</Link>
						</div>
					);
				}
				return <div style={{ width: 130, paddingLeft: 5 }}></div>;
			},
			width: 150,
		},
		{
			title: "Branch",
			field: "branch",
			dataType: "",
			width: 100,
		},
		{
			title: "Consultant type",
			field: "consultantType",
			lookup: { 0: "--", 1: "Employee", 2: "Contracts" },
			dataType: "",
			hidden: true,
			width: 150,
		},
		{
			title: "Job Title",
			field: "jobTitle",
			dataType: "String",
			width: 150,
			render: (rowData) => (
				<div style={{ width: 150, paddingLeft: 5 }}>{rowData.jobTitle}</div>
			),
		},
		{
			title: "Date of Joining",
			field: "joiningDate",
			dataType: "Date",
			width: 150,
			render: (rowData) => (
				<div style={{ width: 150, paddingLeft: 5 }}>{rowData.joiningDate}</div>
			),
		},
		{
			title: "Placement code",
			field: "id",
			width: 150,
			render: (rowData) => {
				if (rowData?.isDraft) {
					return (
						<Link
							to={
								"/console/placements/" + rowData.employeeId + "/" + rowData.id
							}
						>
							<InactiveChip message={rowData.id.toString()} />
						</Link>
					);
				}
				return (
					<Link
						to={"/console/placements/" + rowData.employeeId + "/" + rowData.id}
					>
						<ActiveChip message={rowData.id.toString()} />
					</Link>
				);
			},
		},
		{
			title: "Billable Client",
			field: "billableClientName",
			render: (rowData) => {
				if (!rowData.clients?.billableClient?.clientId) return <p>--</p>;
				return (
					<Link
						to={
							"/console/clientslist/" +
							rowData.clients?.billableClient?.clientId
						}
					>
						{rowData.billableClientName}
					</Link>
				);
			},
			cellStyle: {
				width: 200,
				minWidth: 200,
			},
			dataType: "String",
		},
		{
			title: "End Client",
			field: "endClientNames",
			render: (rowData) => {
				if (!rowData.clients?.endClients?.length) return <p>--</p>;
				return rowData.clients?.endClients?.map((endClient) => {
					return (
						<Link to={"/console/clientslist/" + endClient.clientId}>
							{metaInfo.clientIdToName(endClient.clientId)}
						</Link>
					);
				});
			},
			width: 150,
			dataType: "String",
		},
		{
			title: "Start Date",
			field: "startDate",
			type: "date",
			dataType: "Date",
			width: 120,
		},
		{
			title: "Project End Date",
			field: "projectEndDate",
			type: "date",
			dataType: "Date",
			width: 120,
		},
		{
			title: "Expired",
			field: "aboutToExpire",
			type: "string",
			width: 150,
			render: (row) => {
				if (row.aboutToExpire > 0) {
					return (
						<ActiveChip message={"Remaining " + row.aboutToExpire + " days"} />
					);
				} else if (row.aboutToExpire < 0) {
					return <WarningChip message={-row.aboutToExpire + " days"} />;
				}
				return <p>--</p>;
			},
		},
		{
			title: "Employee Home Address",
			field: "address",
			dataType: "String",
			width: 200,
			render: (rowData) => {
				return (
					<div style={{ width: 200, paddingLeft: 5 }}>
						<table>
							<tr>
								<td>
									<MdLocationCity size={24} />
								</td>
								<td>&nbsp;</td>
								<td>{rowData.address}</td>
							</tr>
						</table>
					</div>
				);
			},
		},
		{
			title: "Category",
			field: "category",
			dataType: "String",
			width: 100,
		},
		{
			title: "Payroll ID",
			field: "payrollId",
			dataType: "String",
			width: 100,
		},
		{
			title: "Project",
			field: "projectName",
			render: ({ project }) => {
				return project?.id && project?.title ? (
					<Link to={`/console/projects/${project.id}`}>{project.title}</Link>
				) : (
					<p>--</p>
				);
			},
			width: 100,
		},
		{
			title: "Notes",
			field: "isPlacementNotesExists",
			dataType: "String",
			// lookup: {
			// 	0: "Notes Available",
			// 	1: "Notes Not Available",
			// },
			render: (rowData) => {
				return (
					<PlacementNotes
						rowData={{
							employeeId: rowData.employeeId,
							placementId: rowData.id,
							placementNotes: rowData.placementNotes,
							employeeName: rowData.employeeName,
						}}
						refetch={refetch}
					/>
				);
			},
			cellStyle: {
				width: 150,
				minWidth: 150,
			},
			width: 100,
		},
		{
			title: "Employee Pay Rate",
			field: "payRate",
			dataType: "String",
			cellStyle: {
				width: 150,
				minWidth: 150,
			},
		},
		{
			title: "Work Location Manager",
			field: "workLocationManager",
			dataType: "String",
			cellStyle: {
				width: 150,
				minWidth: 150,
			},
			render: (rowData) => {
				const managers = rowData.managerDetails?.managers;
				if (managers?.length === 0) {
					return <p>--</p>;
				}
				const { representativeName, email, workPhone } = managers[0];
				return (
					<div>
						<span>{representativeName}</span>
						<br />
						<span>{email}</span>
						<br />
						<span>{workPhone}</span>
					</div>
				)
			},
		},
		{
			title: "Placement Recruiter",
			field: "placementRecruiter",
			dataType: "String",
			cellStyle: {
				width: 150,
				minWidth: 150,
			},
			render: (rowData) => {
				const recruiters = rowData.recruiterDetails?.recruiters;
				if (recruiters?.length === 0) {
					return <p>--</p>;
				}
				const { representativeName, email, workPhone } = recruiters[0];
				return (
					<div>
						<span>{representativeName}</span>
						<br />
						<span>{email}</span>
						<br />
						<span>{workPhone}</span>
					</div>
				)
			},
		},
		{
			title: "Status",
			field: "status",
			lookup: { 0: "Active", 1: "Inactive", 2: "Closed" },
			dataType: "",
			render: (rowData) => {
				if (rowData.status === 0) {
					return <ActiveChip message="Active" />;
				} else if (rowData.status === 1) {
					return <InactiveChip message="Inactive" />;
				} else {
					return <RedChip message="Closed" />;
				}
			},
			customFilterAndSearch: (value, rowData) => {
				if (value.length === 0) return true;
				return value.includes(rowData.status.toString());
			},
			width: 100,
		},
		{
			title: "PO Number",
			field: "poNumber",
			dataType: "String",
			hidden: true,
			width: 120,
		},
		{
			title: "PO Document",
			field: "poDocumentName",
			dataType: "",
			hidden: true,
			render: (rowData) => {
				if (!rowData.poDocuments.length) return <p>--</p>;
				return rowData.poDocuments.map((obj) => {
					return (
						<span>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={obj.work_doc.url}
							>
								{obj.work_doc.name.substr(
									0,
									obj.work_doc.name.lastIndexOf("_")
								)}
							</a>
							<br />
						</span>
					);
				});
			},
			width: 150,
		},
		{
			title: "Actions",
			field: "",
			dataType: "",
			render: (rowData) => {
				return (
					<div className="d-flex">
						<Tooltip title="Edit Placement">
							<Link
								style={{
									color: "red",
									paddingLeft: "5px",
									paddingRight: "5px",
								}}
								to={
									"/console/placements/" + rowData.employeeId + "/" + rowData.id
								}
							>
								<FaEdit size={20} />
							</Link>
						</Tooltip>
						<PlacementAudit placementID={rowData.id} />
					</div>
				);
			},
			width: 100,
		},
	];

	const data = placements?.map((placement) => {
		return {
			id: placement.id,
			employeeId: placement.employee?.id,
			employeeName: placement.employee?.name || "--",
			branch: placement.employee?.branch || "--",
			consultantType: categoryList.includes(placement.employee?.category)
				? categoryList.indexOf(placement.employee?.category)
				: 0,
			jobTitle: placement.jobTitle || "--",
			joiningDate: CalendarFormatter.standardDateFormat(
				placement.employee?.dateOfJoining
			),
			isDraft: placement.draft,
			billableClientName: metaInfo.clientIdToName(placement.clientId) || "--",
			endClientNames:
				placement.clients?.endClients
					?.map((endClient) => metaInfo.clientIdToName(endClient.clientId))
					.join(", ") || "--",
			clients: placement.clients,
			startDate: CalendarFormatter.standardDateFormat(placement.startDate) || "--",
			projectEndDate: CalendarFormatter.standardDateFormat(
				placement.projectEndDate
			) || "--",
			aboutToExpire: getAboutToExpire(
				placement.projectEndDate,
				placement.closingReason
			),
			address: placement.employee?.address || "--",
			category: placement.employee?.category || "--",
			payrollId: placement.employee?.payrollId || "--",
			projectName: placement.project?.title || "--",
			project: placement.project,
			isPlacementNotesExists: placement.placementNotes?.length ? htmlToText(placement.placementNotes) : "",
			placementNotes: placement.placementNotes,
			payRate: placement.payRateDetails?.employeePayRate || "--",
			workLocationManager: formatManager(placement.managerDetails?.managers),
			placementRecruiter: formatManager(placement.recruiterDetails?.recruiters),
			managerDetails: placement.managerDetails,
			recruiterDetails: placement.recruiterDetails,
			status: placement?.closingReason
				? statusList.indexOf("Closed")
				: new Date(placement.projectEndDate) < new Date()
				? statusList.indexOf("Inactive")
				: statusList.indexOf("Active"),
			poNumber: placement.payRateDetails?.poNumber || "--",
			poDocumentName: placement.poDocuments
				?.map((doc) => doc.work_doc?.name)
				.join(", "),
			poDocuments: placement.poDocuments,
		};
	}) || [];

	const clientViewColumns = columns.filter((col) => col.field !== "billableClientName");

	const PlacementAuditAction = {
		icon: () => <PlacementAudit />,
		tooltip: "Placement Audit",
		isFreeAction: true,
		position: "toolbar",
	};

	const getRowHeight = React.useCallback((params) => {
		const { data } = params;
		const maxArrayLength = Math.max(
			data?.placementRecruiter !== '--' ? 3 : 0,
			data?.workLocationManager !== '--' ? 3 : 0,
			data?.poDocuments?.length ?? 0,
			data?.clients?.endClients?.length ?? 0,
			1
		);
		return 32 * maxArrayLength;
	}, []);

	return (
		<div>
			<FlairTable
				title="Placements Report"
				columns={clientId ? clientViewColumns : columns}
				data={data.length > 0 ? data : null}
				actions={NewPlacementAction}
				otherAction={PlacementAuditAction}
				getRowHeight={getRowHeight}
				isLoading={isLoading}
			/>
		</div>
	);
}

export default Presentation;

const statusList = ["Active", "Inactive", "Closed"];
const categoryList = ["--", "W2", "C2C"];

const getAboutToExpire = (projectEndDate, closingReason) => {
	let days = differenceInDays(new Date(projectEndDate), new Date());
	if (closingReason) return "";
	else if (days <= 30) return days;
	else return "";
};

const formatManager = (managers) => {
	if (managers?.length === 0) {
		return "--";
	}
	const { representativeName, email, workPhone } = managers[0];
	return `${representativeName} - ${email} - ${workPhone}`;
};
