import React, { useEffect, useRef } from 'react'
import { NewInvoiceContextProvider, useNewInvoiceContext } from "../NewInvoice/context";
import validation from "../../../../shared/validation"
import StandardTimeTable from '../NewInvoice/StandardTimeTable';
import OTtimeTable from '../NewInvoice/OTtimeTable';
import ExpenseTable from '../NewInvoice/ExpenseTable';
import ByExternalTable from "./ByExternalTable"
import validate from "../../../../shared/validation"
import { Button, Grid } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import HTMLReactParser from "html-react-parser"
import AdditionalItemsTable from '../NewInvoice/AdditionalItemsTable';
import DiscountTable from '../ListingInvoices/Actions/DiscountTable';

function InvoicePreviewComponent(props) {
  const { state, services, stateSetters } = useNewInvoiceContext();
  const componentRef = useRef();
  let invoiceID = ""
  const hideGrids = !!props.invoiceID

  useEffect(() => {
    invoiceID = !props.invoiceID ? decodeURIComponent(
      validation.getQueryParameter("invoiceID")
    ) : props.invoiceID;
    stateSetters.setInvoiceID(invoiceID)
    services.getInvoiceDetails(invoiceID, "preview")
    services.getCompanyDetails("preview")
  }, [])

  const printInvoice = () => {
    var prtContent = document.getElementById("preview-of-the-invoice");
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }

  if (state.invoicePreviewLoader)
    return (
      <div>
        <div className="spinner">
        </div>
      </div>
    )

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }} >
        <span />
        <ReactToPrint
          documentTitle={invoiceID}
          trigger={() => <Button variant='contained' color='primary' onClick={printInvoice} >
            Print
          </Button>}
          content={() => componentRef.current}
        />
      </div>

      <Grid container>
        <Grid item xs={12} sm={hideGrids ? 12 : 2} />
        <Grid ref={componentRef} item xs={12} sm={hideGrids ? 12 : 8} >
          <div style={{ padding: '30px' }} >
            <div>
              <header style={{ display: 'flex', justifyContent: 'space-between', borderBottom: "1px solid #3989c6" }}>
                <span style={{ padding: "20px", float: "left" }}>
                  <a target='_blank' href={state.companyDetails?.web_url}>
                    <img style={{ maxHeight: "120px", maxWidth: "120px", width: "auto", height: "auto" }} src={state.companyDetails?.images?.companyLogo} />
                  </a>
                </span>
                <span style={{ padding: "10px", float: "right", textAlign: "right" }}>
                  <span style={{ fontSize: "30px" }}>
                    <a target='_blank' href={state.companyDetails?.web_url}>
                      {state.companyDetails?.companyName}
                    </a>
                  </span>
                  <br />
                  <span style={{ fontSize: "15px" }}>{state.companyDetails?.contactDetails?.address}</span><br />
                  <span style={{ fontSize: "15px" }}>{state.companyDetails?.contactDetails?.phoneno}</span><br />
                  <span style={{ fontSize: "15px" }}>{state.companyDetails?.contactDetails?.accountsMailId}</span><br />
                </span>
              </header>
              <main>
                <div style={{ width: "100%", height: "170px" }}>
                  <span style={{ padding: "10px", float: "left", width: "50%" }}>
                    <span style={{ fontSize: "18px" }}>INVOICE TO:</span><br />
                    <span style={{ fontSize: "30px", fontWeight: "600" }}>{state.clientDetails?.name}</span><br />
                    <span className='normal'>{state.clientDetails?.name}</span><br />
                    <span className='normal'>{state.clientDetails?.address}</span><br />
                  </span>
                  <span style={{ padding: "10px", float: "right", textAlign: "right" }}>
                    <span style={{ color: "#3989c6", fontSize: "30px" }} className="invoice-id">{state.invoiceID}</span><br />
                    <span className="date">Date of Invoice:{validate.dateFormatter(state.invoiceDate)} </span><br />
                    <span className="date"> Due Date: {validate.dateFormatter(state.dueDate)}</span><br />
                    <span className="date">Net Terms: {state.netTerms}</span><br />
                  </span>
                </div>
                <StandardTimeTable hideActions={true} />
                <OTtimeTable hideActions={true} />
                <ExpenseTable hideActions={true} />
                <AdditionalItemsTable hideActions={true} />
                <DiscountTable />
                <ByExternalTable />
                <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center",marginTop: "1rem" }} >
                  <div style={{ textAlign: "left" }}>
                    <div>
                      <span style={{ marginTop: "2rem", fontSize: "15px", fontWeight: "600" }}>PAYABLE TO:</span>
                      <div style={{ marginBottom: "1rem", fontSize: "30px" }}>{HTMLReactParser('<span style="font-size:15px" >' + state.payableTo + '</span>')}</div>
                    </div>
                    <div>
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>NOTES:</span>
                      <div style={{ marginBottom: "1rem", fontStyle: "normal", lineHeight: "inherit" }}>{state.notes ? state.notes : "--"}</div>
                    </div>
                    <div>
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>STATEMENT MEMO:</span>
                      <div style={{ marginBottom: "1rem", fontStyle: "normal", lineHeight: "inherit" }}>{state.statementMemo ? state.statementMemo : "--"}</div>
                    </div>
                    <div>
                      <span style={{ fontSize: "15px", fontWeight: "600" }}>ADDITIONAL INFO:</span>
                      <div style={{ marginBottom: "1rem", fontStyle: "normal", lineHeight: "inherit" }}>{state.additionalInfo ? HTMLReactParser(state.additionalInfo) : "--"}</div>
                    </div>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <table border="0" cellspacing="0" cellpadding="0">
                      <tr>
                        <th style={{ textAlign: "right", whiteSpace: "nowrap", fontWeight: "400", fontSize: "16px", padding: "10px", background: "#eee", borderBottom: "1px solid #fff" }}>TOTAL</th>
                        <td style={{ textAlign: "right", whiteSpace: "nowrap", fontSize: "1.2em", background: "#000", color: "#fff", padding: "10px" }}>{validate.currencyFormatterUs(state.subTotal)}</td>
                      </tr>
                      {
                        state?.additionalItems?.map((item) => {
                          return (
                            <tr>
                              <th style={{ textAlign: "right", whiteSpace: "nowrap", fontWeight: "400", fontSize: "16px", padding: "10px", background: "#eee", borderBottom: "1px solid #fff" }}>{item?.serviceSummary.toUpperCase()}</th>
                              <td style={{ textAlign: "right", whiteSpace: "nowrap", fontSize: "1.2em", background: "#000", color: "#fff", padding: "10px" }}>{validate.currencyFormatterUs(item?.amount)}</td>
                            </tr>
                          )
                        })
                      }
                      <tr>
                        <th style={{ textAlign: "right", whiteSpace: "nowrap", fontWeight: "400", fontSize: "16px", padding: "10px", background: "#eee", borderBottom: "1px solid #fff" }}>DISCOUNT</th>
                        <td style={{ textAlign: "right", whiteSpace: "nowrap", fontSize: "1.2em", background: "#000", color: "#fff", padding: "10px" }}>{validate.currencyFormatterUs(services.calculateDiscount(state.discount))}</td>
                      </tr>
                      {
                        state?.isPaymentDone ?
                          state?.paymentHistory?.map((payment) => {
                            return (
                              <>
                                <tr>
                                  <th style={{ textAlign: "right", whiteSpace: "nowrap", fontWeight: "400", fontSize: "16px", padding: "10px", background: "#eee", borderBottom: "1px solid #fff" }}>PAYMENT AMOUNT</th>
                                  <td style={{ textAlign: "right", whiteSpace: "nowrap", fontSize: "1.2em", background: "#000", color: "#fff", padding: "10px" }}>{validate.currencyFormatterUs(payment?.paymentAmount)}</td>
                                </tr>
                                {
                                  payment?.discountDetails?.map((discount) => {
                                    return (
                                      <tr>
                                        <th style={{ textAlign: "right", whiteSpace: "nowrap", fontWeight: "400", fontSize: "16px", padding: "10px", background: "#eee", borderBottom: "1px solid #fff" }}>{discount?.name}{discount?.type === "byPercentage" ? "("+discount?.value+"%)" : ""}</th>
                                        <td style={{ textAlign: "right", whiteSpace: "nowrap", fontSize: "1.2em", background: "#000", color: "#fff", padding: "10px" }}>{  `$ ${services.calculateDiscountWithSubTotal(state?.subTotal, [discount])}`}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </>
                            );
                          })
                          :<></>
                      }
                      <tr>
                        <th style={{ textAlign: "right", whiteSpace: "nowrap", fontWeight: "400", fontSize: "16px", padding: "10px", background: "#eee", borderBottom: "1px solid #fff" }}>GRAND TOTAL</th>
                        <td style={{ textAlign: "right", whiteSpace: "nowrap", fontSize: "1.2em", background: "#000", color: "#fff", padding: "10px" }}>{validate.currencyFormatterUs(state.grandTotal)}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </main>
              <footer style={{ width: "100%", textAlign: "center", color: "#777", borderTop: "1px solid #aaa", padding: "4px 0", marginTop: "10px" }} >
                Note: Any Discrepancy has to be reverted in 7 days post which no changes will be accepted.<br />
                Invoice was created on a computer and is valid without the signature and seal.
              </footer>
            </div>
            <div></div>
          </div>
        </Grid>
        <Grid item xs={12} sm={hideGrids ? 12 : 2} />
      </Grid>
      <div className='text-center' >

      </div>
    </div>
  )
}

const InvoicePreview = (props) => <NewInvoiceContextProvider><InvoicePreviewComponent {...props} /> </NewInvoiceContextProvider>
export default InvoicePreview